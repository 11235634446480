var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Typography ")]),_c('v-card-text',[_c('v-item-group',{attrs:{"mandatory":""}},[_c('v-sheet',{staticClass:"overflow-y-auto",attrs:{"max-height":"600","tile":""}},_vm._l((_vm.classes),function(ref){
            var c = ref[0];
            var text = ref[1];
            var size = ref[2];
            var weight = ref[3];
            var spacing = ref[4];
            var margin = ref[5];
return _c('v-item',{key:c,scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
            var toggle = ref.toggle;
return [_c('v-sheet',{staticClass:"px-4 pt-4 pb-3",staticStyle:{"cursor":"pointer"},attrs:{"color":active ? _vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4' : undefined,"tile":""},on:{"click":toggle}},[_c('div',{staticClass:"transition-swing",class:[("text-" + c), active && ("mb-" + margin)],domProps:{"textContent":_vm._s(text)}}),_c('v-expand-transition',[(active)?_c('v-responsive',{attrs:{"max-width":"350"}},[_c('div',{staticClass:"d-flex justify-space-between text-caption"},[_c('div',[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Font ")]),_c('div',{staticClass:"font-weight-medium"},[_vm._v(" Roboto ")])]),_c('div',[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Weight ")]),_c('div',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(weight)}})]),_c('div',[_c('div',{staticClass:"grey--text mb-2"},[_vm._v(" Size ")]),_c('div',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(size)}})]),_c('div',[_c('div',{staticClass:"grey--text text-darken-1 mb-2"},[_vm._v(" Letter spacing ")]),_c('div',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(spacing)}})])])]):_vm._e()],1)],1)]}}],null,true)})}),1)],1)],1)],1),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v("Text breakpoints")]),_c('v-card-text',[_c('div',[_c('v-item-group',{staticClass:"pa-2 d-flex justify-center",attrs:{"mandatory":""},model:{value:(_vm.model2),callback:function ($$v) {_vm.model2=$$v},expression:"model2"}},_vm._l((_vm.sizes),function(ref,i){
            var icon = ref[0];
            var bp = ref[1];
            var size = ref[2];
return _c('v-item',{key:i,attrs:{"value":size},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
            var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('div',{staticClass:"ma-3 text-center",class:[(active || hover) ? 'text--primary' : 'text--secondary'],staticStyle:{"cursor":"pointer"},on:{"click":toggle}},[_c('v-icon',{staticClass:"pa-1 mb-1",attrs:{"color":(active || hover) ? 'grey darken-4' : 'grey lighten-1'},domProps:{"textContent":_vm._s(icon)}}),_c('div',{staticClass:"text-caption",domProps:{"textContent":_vm._s(bp)}})],1)]}}],null,true)})]}}],null,true)})}),1),_c('div',{staticClass:"text-h5 mb-6 text-center"},[_c('code',{domProps:{"textContent":_vm._s(_vm.typeClass)}})]),_c('v-card',{staticClass:"d-flex align-center justify-center pa-4 mx-auto",attrs:{"max-width":"550","min-height":"76","outlined":""}},[_c('div',{class:("text-" + _vm.model2)},[_vm._v(" Example Heading ")])])],1)])],1),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v("Font weight")]),_c('v-card-text',[_c('p',{staticClass:"font-weight-black"},[_vm._v(" Black text. ")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Bold text. ")]),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" Medium weight text. ")]),_c('p',{staticClass:"font-weight-regular"},[_vm._v(" Normal weight text. ")]),_c('p',{staticClass:"font-weight-light"},[_vm._v(" Light weight text. ")]),_c('p',{staticClass:"font-weight-thin"},[_vm._v(" Thin weight text. ")]),_c('p',{staticClass:"font-italic"},[_vm._v(" Italic text. ")])])],1),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(" Decoration ")]),_c('v-card-text',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"#"}},[_vm._v("Non-underlined link")]),_c('div',{staticClass:"text-decoration-line-through"},[_vm._v(" Line-through text ")]),_c('div',{staticClass:"text-decoration-overline"},[_vm._v(" Overline text ")]),_c('div',{staticClass:"text-decoration-underline"},[_vm._v(" Underline text ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }